import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from '../providers';

export const useSessionId = (clearSessionUrl: string) => {
  const [sessionId, setSessionId] = useState<string>('');
  const { token } = useAuth()

  useEffect(() => {
    const newSessionId = uuidv4();
    setSessionId(newSessionId);

    return () => {
      if (clearSessionUrl && token) {
        fetch(`${clearSessionUrl}/${newSessionId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
          .catch(error => console.error('Erreur lors du nettoyage de la session', error));
      }
    };
  }, [clearSessionUrl, token]);

  return sessionId;
};
