import React from "react"
import { useState,useEffect } from "react"

export const NoCreditModal = () =>{
    const pulsateAnimation = `
        @keyframes pulsate {
            0% {
                transform: scale(1.0);
            }
            25% {
                transform: scale(1.01);
            }
            50% {
                transform: scale(1.0);
            }
            100% {
                transform: scale(1);
            }
        }
    `;
    return(
        <>
        <style>{pulsateAnimation}</style> 
        <div className="h-8 flex flex-row w-full bg-[#f27405] text-black items-center justify-center overflow-hidden" style={{zIndex: '9999', animation: 'pulsate 1.2s 4'}}>
            <p className="text-sm font-trendaSemiBold">You have run out of credits. Your application usage is now limited. Please refer to support.ed<span className="font-sans">@</span>equancy.com to get more credits.</p>
        </div>
        </>
    )
}