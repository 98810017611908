import React from "react";
import { useState, useEffect} from "react";
import DropzoneLargeDoc from "./DropzoneLargeDoc";
import { LoaderCircle } from "../../components";
import { useSessionId } from "../../hooks";
import { useAuth } from "../../providers";
import { SVGIcon } from "../../assets";
import "./LargeDoc.css"
import { ChatInput } from "../SecureGPT/ChatInput";
import { AskConversationProps } from "./ChatConversationLargeDoc";
import { DocumentDropdown,DocumentDropdownGeneral,DocumentDisplay } from "./Documents";
import { useTypingEffect } from "../../hooks/typingEffect";
import { useCredit } from "../../providers";
import { translations } from "../../translations";

interface UploadedDocRes {
    [key: string]: string;
}


export const LargeDoc = () => {
    // const [dropdownOpen, setDropdownOpen] = useState<boolean>(true)
    const {language} = useAuth()
    const [files, setFiles] = useState<File[] | []>([]);
    const [urlDoc, setUrlDoc] = useState<string>('')
    const [summaryChecked, setSummaryChecked] = useState<boolean>(true)
    const [uploadLoader, setUploadLoader] = useState<boolean>(false)
    const [uploadSuccess, setUploadSuccess] = useState<boolean>(false)
    const [uploadedDocuments, setUploadedDocuments] = useState<UploadedDocRes>({})
    const sessionId = useSessionId(`/api/multi_doc/clear_session`);
    const [showDropzone, setShowDropzone] = useState<boolean>(true)
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [messages, setMessages] = useState<AskConversationProps[]>([]);
    const [input, setInput] = useState<string>('');
    const [resToQuestion, setResToQuestion] = useState(null)
    const [loaderAsk, setLoaderAsk] = useState(false)
    // const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);
    const [openDropdown, setOpenDropdown] = useState<number | 'general' | null>(null);
    const { token } = useAuth()
    const [triggerReset, setTriggerReset] = useState<boolean>(false)
    const [triggerResetDoc, setTriggerResetDoc] = useState<boolean>(false)
    const [currentlyUploading, setCurrentlyUploading] = useState<number>(0)
    const [loaderLongUpload, setLoaderLongUpload] = useState<boolean>(false)
    const [fileUploadError, setFileUploadError] = useState<string[]>([])
    const [totalFileNumber, setTotalFileNumber] = useState<number>(0)
    const translationTexts = translations['multi_doc'][language as keyof typeof translations['multi_doc']];
    const [typedText] = useTypingEffect(translationTexts.welcome_message,40)
    const { remainingCredit, setRemainingCredit } = useCredit()
    
    const resetAll = () =>{
        setTriggerReset(true)   
    }

    const resetAllDoc = () =>{
        setTriggerResetDoc(true)   
    }


    useEffect(()=>{
        if(triggerResetDoc){
            setMessages([])
            setFiles([])
            setOpenDropdown('general')
            setUploadSuccess(false)
            setUploadedDocuments({})
            setInput('')
            setTotalFileNumber(0)
            setLoaderLongUpload(false)
            setUploadLoader(false)
            setSelectedFiles([])

            fetch(`/api/multi_doc/clear_session/${sessionId}`, {
                method: 'DELETE',
                headers: {
                  'Authorization': `Bearer ${token}`
                }
              })
            .catch(error => console.error('Erreur lors du nettoyage de la session', error));
        }
    },[triggerResetDoc])

    useEffect(()=>{
        if(triggerReset){
            setMessages([])
            // setFiles([])
            setOpenDropdown('general')
            // setUploadSuccess(false)
            // setUploadedDocuments({})
            setInput('')

            // fetch(`/api/multi_doc/clear_session/${sessionId}`, {
            //     method: 'DELETE',
            //     headers: {
            //       'Authorization': `Bearer ${token}`
            //     }
            //   })
            // .catch(error => console.error('Erreur lors du nettoyage de la session', error));
        }
    },[triggerReset])
    

    const toggleDropdown = (identifier: number | 'general') => {
        if (openDropdown === identifier) {
          setOpenDropdown(null);
        } else {
          setOpenDropdown(identifier);
        }
      };

    const handleSubmitGeneral = async() =>{
        if(loaderAsk) return;
        if(input.length === 0 || Object.entries(uploadedDocuments).length === 0) return
        if(openDropdown !== 'general') toggleDropdown('general')
        setLoaderAsk(true)
        setMessages(prevMessages => [...prevMessages,{
            type:'Ask',
            text : input
        }])
        setInput('')
        try{
            const response = await fetch(
                `/api/multi_doc/ask_question`,
                {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        app_session_id: sessionId,
                        question: input,
                    })
                }
            );

            const data = await response.json()
            setResToQuestion(data.answer.answer)            
            setLoaderAsk(false)
            setMessages(prevMessages => [...prevMessages,{
                type:'Answer',
                text : data.answer.answer
            }])
            if (remainingCredit && data.credit) {
                const new_credit = remainingCredit - data.credit
                setRemainingCredit(new_credit)
            }

        }catch(error){
            console.log(error)
            setLoaderAsk(false)
            setInput('')
        }
        return;
    }

    const removeFile = (fileName: string) => {
        if(loaderLongUpload) return;
        const updatedFiles = selectedFiles.filter(file => file.name !== fileName);
        setSelectedFiles(updatedFiles);
        setFiles(updatedFiles);
    };

    useEffect(()=>{
        //console.log(fileUploadError)
        if(fileUploadError && fileUploadError.length > 0 ){
            setLoaderLongUpload(false)
            //setCurrentlyUploading(0)
        }
    },[fileUploadError])

    const handleUpload = async () => {
        if(loaderLongUpload) return;
        if (files.length === 0) return;
        setUploadSuccess(false);
        setUploadLoader(true);
        setLoaderLongUpload(true)
    
        let uploadCount = 0;
    
        files.forEach((file) => {
            const formData = new FormData();
            setTotalFileNumber(prev => prev + 1)
            formData.append("app_session_id", sessionId);
            formData.append("files_present", files.length > 0 ? "true" : "false");
            formData.append('files', file);
            formData.append("gen_summary", String(summaryChecked));
            if (urlDoc) formData.append("url", urlDoc);
    
            fetch(`/api/multi_doc/upload_documents`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            })
            .then(response => {
                //setFileUploadError([...fileUploadError!, file.name]) //testing
                if (!response.ok) {
                    if (response.status === 504) {
                        setFileUploadError([...fileUploadError!, file.name])
                        setTotalFileNumber(prev => prev - 1)
                        //throw new Error(`Timeout occurred while uploading file ${file.name}`);
                    }
                    return
                }
                return response.json();
            })
            .then(data => {
                setUploadedDocuments(prevDocs => ({
                    ...prevDocs,
                    ...data.docs_info
                }));
                if (remainingCredit && data.credit) {
                    const new_credit = remainingCredit - data.credit
                    setRemainingCredit(new_credit)
                }
            })
            .catch(error => {
                console.error("Error uploading file:", error);
            })
            .finally(() => {
                setUploadSuccess(true);
                setTimeout(() => {
                    setUploadLoader(false);
                }, 2000);
                uploadCount++;
                setSelectedFiles([]);
                if (uploadCount === files.length) {
                    setLoaderLongUpload(false)
                    //setFiles([])
                }
            });
        });
    };

    useEffect(()=>{        
        setCurrentlyUploading(totalFileNumber  - Object.entries(uploadedDocuments).length)
    },[uploadedDocuments,totalFileNumber])

    // const handleUpload = async () => {
    //     if (files.length === 0) return;
    //     setUploadLoader(true);

    //     const uploadPromises = files.map(async (file) => {
    //         const formData = new FormData();
    //         formData.append("files_present", files && files.length > 0 ? "true" : "false");
    //         formData.append('files', file); // Assuming the endpoint expects a single file per request
    //         formData.append("session_id", sessionId);
    //         formData.append("gen_summary", String(summaryChecked));
    //         if (urlDoc) formData.append("url", urlDoc);

    //         try {
    //             const response = await fetch(`/api/multi_doc/upload_documents`, {
    //                 method: "POST",
    //                 headers: {
    //                     'Authorization': `Bearer ${token}`,
    //                 },
    //                 body: formData,
    //             });
    //             if (!response.ok) {
    //                 throw new Error(`Failed to upload file ${file.name}`);
    //             }
    //             return response.json();
    //         } catch (error) {
    //             console.error("Error uploading file:", error);
    //             return null; // Or handle more gracefully
    //         }
    //     });

    //     Promise.all(uploadPromises).then((results) => {
    //         const newUploadedDocs = results.reduce<UploadedDocRes>((acc, curr) => {
    //             return { ...acc, ...curr };
    //         }, {});
        

    //         setUploadedDocuments(newUploadedDocs);
    //         setUploadSuccess(true);
    //         setTimeout(() => {
    //             setUploadLoader(false);
    //         }, 2000);
    //         setSelectedFiles([])
    //     }).catch((error) => {
    //         console.error("An error occurred during the uploads:", error);
    //         setUploadLoader(false);
    //     });
    // };
    
    // const handleUpload = async () => {
    //     if(files.length === 0 && urlDoc.length === 0) return;
    //     if(uploadLoader) return;
    //     setUploadSuccess(false);
    //     setUploadLoader(true)
    //     if (!files || token === null || sessionId === "") return;

    //     const formData = new FormData();
    //     if (files.length === 0) {
    //         const emptyBlob = new Blob([], { type: "application/octet-stream" });
    //         formData.append('files', emptyBlob, "placeholder.txt");
    //     } else {
    //         files.forEach(file => {
    //             formData.append('files', file);
    //         });
    //     }
    //     formData.append("files_present", files && files.length > 0 ? "true" : "false");
    //     formData.append("gen_summary", `${summaryChecked}`);
    //     formData.append("session_id", sessionId);
    //     if(urlDoc != '') formData.append("url", urlDoc);

    //     try {
    //         const response = await fetch(
    //             `/api/multi_doc/upload_documents`,
    //             {
    //                 method: "POST",
    //                 headers: {
    //                     'Authorization': `Bearer ${token}`
    //                 },
    //                 body: formData,
    //             }
    //         );
    //         const data = await response.json();
    //         setUploadedDocuments(data)
    //         setTimeout(() => {
    //             setUploadSuccess(true);
    //             setTimeout(() => {
    //                 setUploadLoader(false);
    //             }, 2000); 
    //         }, 1000);
    //         setSelectedFiles([])
    //         setUrlDoc('')
    //     } catch (error) {
    //         console.error("Erreur lors de l'envoi du document:", error);
    //         setUploadLoader(false)
    //     }
    //     return;
    // }

    

    return (
        <div className="relative flex flex-col items-center pb-10 h-full">
            <div className="relative flex flex-col items-start justify-start px-10 pb-10 w-full gap-8">
                <div className="flex flex-col w-full bg-welcomeBackground  rounded-bl-[250px] rounded-br-[250px] p-5">
                    <div className="flex flex-row w-full justify-center">
                        <p className="text-textPrimary font-secondary text-4xl tracking-wide">{typedText}</p>
                    </div>
                    <div className="flex gap-1 flex-col bg-transparent p-2 w-full rounded-lg justify-center items-center text-sm font-bold text-textPrimary text-center overflow-hidden">
                        <p className="w-[70%]">
                            {translationTexts.description_app}
                        </p>
                    </div>
                </div>  
                <div className="flex flex-row w-full gap-4"> 
                    {showDropzone ? 
                    <>
                        <div className="flex flex-col w-full">
                            <DropzoneLargeDoc language={language} loaderLong={loaderLongUpload} setFiles={setFiles} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles}/> 
                        </div>
                        <div onClick={() => setShowDropzone(!showDropzone)} className="cursor-pointer flex items-center justify-center bg-buttonBg border text-buttonText border-buttonBorder bg-opacity-55 px-3 rounded-xl">
                            <SVGIcon size="20px" icon="LINKS_ICON"/>
                        </div> 
                    </>
                        : 
                    <>
                        <div onClick={() => setShowDropzone(!showDropzone)} className="cursor-pointer flex items-center justify-center bg-buttonBg border border-buttonBorder bg-opacity-55 px-3 rounded-xl">
                            <SVGIcon size="20px" icon="UPLOAD_ICON"/>
                        </div>
                        <div className="flex flex-col w-full bg-buttonBg bg-opacity-75 p-3.5 border border-buttonBorder justify-center rounded-xl">
                            <input placeholder="URL of the document" className="placeholder-inputText border border-inputBorder p-3 bg-inputBg rounded-xl focus:outline-none" type="text" value={urlDoc} onChange={(e) => setUrlDoc(e.target.value)} />
                        </div> 
                    </>
                    }
                    <div className="flex flex-col w-4/11 justify-between items-start gap-3">
                        <div className="flex flex-row justify-center gap-3">
                            <div onClick={() => setSummaryChecked(!summaryChecked)}  className="relative cursor-pointer">
                                <input
                                    id="summaryRadio"
                                    className="opacity-0 absolute h-5 w-5  cursor-pointer border border-inputBorder"
                                    type="radio"
                                    name="summary"
                                    checked={summaryChecked}
                                />
                                <div className="cursor-pointer flex justify-center items-center w-7 h-5 border border-black rounded cursor-pointer">
                                    {summaryChecked && (
                                    <svg className="w-5 h-5 text-black" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="#22c55f">
                                        <path d="M5 13l4 4L19 7"></path>
                                    </svg>
                                    )}
                                </div>
                            </div>
                            <label htmlFor="summaryRadio" className="text-sm cursor-pointer">{translationTexts.generate_title_summary}</label>
                        </div>
                        <div className="flex flex-row justify-center w-full h-full overflow-hidden">
                            <button disabled={uploadLoader || (files.length === 0 && urlDoc.length === 0) || loaderLongUpload} onClick={handleUpload} className="cursor-pointer flex items-center justify-center gap-3 w-full text-sm px-4 py-1 bg-buttonBg bg-opacity-75 text-buttonText rounded-lg border hover:bg-buttonHoverBg hover:text-buttonHoverText hover:border-buttonHoverBorder focus:outline-none group">{translationTexts.upload_docs}{!uploadLoader && <SVGIcon size="20px" icon="ARROW_SMALL_ICON" className="group-hover:invert"/>}</button>
                            {uploadLoader && 
                                <div className="flex items-center justify-center scale-50">
                                    <LoaderCircle uploadSuccess={uploadSuccess}/>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {files.length > 0 && loaderLongUpload &&  <p className="text-sm">{translationTexts.loader_upload1} {currentlyUploading} {translationTexts.loader_upload2}{currentlyUploading > 1 ? 's': ''}..</p>}
                {fileUploadError && fileUploadError.length > 0 && !loaderLongUpload &&   
                <>
                <p className="text-sm">{translationTexts.timeout_error1} {fileUploadError.length > 1 ? 'files' : 'file'}. {translationTexts.timeout_error2} {fileUploadError.length > 1 ? '' : 'a'} {translationTexts.timeout_error3}{fileUploadError.length > 1 ? 's' : ''}:</p>
                <ol className="text-sm">
                    {fileUploadError?.map((error, index) => (
                        <li key={index}>•{'\u00A0'}{error}</li>
                    ))}
                </ol>
                </>
                }
                <div className="grid grid-cols-2 gap-5 w-full mt-4">
                    {selectedFiles.map((file) => (
                        <DocumentDisplay fileName={file.name} onRemove={() => removeFile(file.name)}/>    
                    ))}
                </div>
                <div className="w-full overflow-auto flex flex-col gap-5 min-h-[420px] scrollbar-hide">
                    {uploadSuccess && uploadedDocuments && (
                        Object.entries(uploadedDocuments).map(([fileName, summary], index) => (
                            <DocumentDropdown language={language} key={index} triggerResetDoc={triggerResetDoc} setResetTriggerDoc={setTriggerResetDoc} resetTrigger={triggerReset} setResetTrigger={setTriggerReset} summary={summary || ''} fileName={fileName} token={token!} session_id={sessionId!} isOpen={openDropdown === index}  toggleDropdown={() => toggleDropdown(index)} index={index}/>
                        ))
                    )}
                    {messages.length > 0 && 
                    uploadSuccess && uploadedDocuments && (
                        <DocumentDropdownGeneral language={language} messages={messages} summary="" token={token!} session_id={sessionId!} isOpen={openDropdown === 'general'} toggleDropdown={() => toggleDropdown('general')} />
                    )}
                </div>
            </div>
            <div className="w-full">
                <ChatInput language={language} resetDoc={true} setResetDoc={setTriggerResetDoc}  disabled={Object.entries(uploadedDocuments).length === 0} placeHolderMess={translationTexts.placeholder_chat_input_general} resetText={translationTexts.reset_text} resetMessages={resetAll} onSendMessage={handleSubmitGeneral} handleInputClick={()=>{}} input={input} setInput={setInput} loading={loaderAsk}/>
            </div>
        </div>
    )
}