import React from "react";
import { useDropzone } from "react-dropzone";
import { PDFDocument } from 'pdf-lib';
import { useState } from "react";
import { SVGIcon } from "../../assets/SVGIcon";
import { translations } from "../../translations";

interface FileDropzoneProps {
  setFiles: React.Dispatch<React.SetStateAction<File[] | []>>;
  setFileNames?: React.Dispatch<React.SetStateAction<string>>;
  selectedFiles : File[];
  setSelectedFiles:React.Dispatch<React.SetStateAction<File[] | []>>;
  fileName?: string;
  loaderLong: boolean;
  language: string;
}

const DropzoneLargeDoc: React.FC<FileDropzoneProps> = ({
  setFiles,
  setFileNames,
  fileName,
  selectedFiles,
  setSelectedFiles,
  loaderLong,
  language
}) => {

    // const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

    const onDrop = (acceptedFiles: File[]) => {
        const newFiles = acceptedFiles.filter(
        af => !selectedFiles.some(f => f.name === af.name)
        );
        const updatedFiles = [...selectedFiles, ...newFiles];
        setSelectedFiles(updatedFiles);
        setFiles(updatedFiles);
    };

    

    const { getRootProps, getInputProps, isDragReject, fileRejections } = useDropzone({
        accept: {
            "application/pdf": [".pdf"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
            "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
            "text/plain": [".txt"],
            "text/csv": [".csv"],
            "text/vtt": [".vtt"],
            "text/html": [".html"]
        },
        onDrop,
        maxFiles: 10,
        multiple: true,
        maxSize: 200 * 1024 * 1024, // 200MB in bytes
        noClick: loaderLong
    });

    const translationTexts = translations['multi_doc'][language as keyof typeof translations['multi_doc']];


  return (
    <>
        <div
            {...getRootProps()}
            className={`dropzone border-dashed border-2 border-buttonBorder ${
            fileName && "border-text-primary-light"
            } rounded-xl p-3.5 text-center cursor-pointer w-full h-full`}
        >
            <input {...getInputProps()} />
            <p className="font-bold">
               {translationTexts.dropzone_text}
            </p>
            <p className="text-sm text-textSecondary mt-1">
                {translationTexts.dropzone_accepted_file} PDF, DOCX, PPTX, TXT, HTML, CSV, XLSX, VTT
            </p>
            {fileRejections.length > 0 && (
                <p className="text-sm text-red-500 mt-2">
                   {translationTexts.dropzone_file_error}
                </p>
            )}
        </div>
        {/* <div className="w-full flex flex-col gap-1 p-2 justify-center items-center ">
            <ul className="mt-2 w-full">
                    {selectedFiles.map((file, index) => (
                        <li key={index} className="w-full flex items-center justify-between p-2">
                            <SVGIcon size="16px" icon="CUSTOM_FINANCE_ICON" />
                            <span className="flex-1 ml-2">{file.name}</span>
                            <button onClick={() => removeFile(file.name)}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 6L6 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>
                        </li> 
                    ))}
            </ul>
        </div> */}
    </>
        
  );
};

export default DropzoneLargeDoc;
