import { useState, useEffect } from "react";
import { useAuth } from "../providers";

interface Languages {
  [key: string]: string;
}

function orderLanguages(languages: Languages): Languages {
  const priorityLanguages = ['fr', 'en', 'es', 'de', 'it'];
  const orderedLanguages: Languages = {};

  priorityLanguages.forEach(lang => {
    if (languages[lang]) {
      orderedLanguages[lang] = languages[lang];
    }
  });

  Object.keys(languages).forEach(lang => {
    if (!orderedLanguages[lang]) {
      orderedLanguages[lang] = languages[lang];
    }
  });

  return orderedLanguages;
}

function useTranslationConfig({ sessionId }: { sessionId: string }) {
  const [languages, setLanguages] = useState<Languages>({});
  const [docTypes, setDocTypes] = useState<string[]>([]);
  const [mapOpt, setMapOpt] = useState<{ [key: string]: string[] }>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { token } = useAuth()

  useEffect(() => {
    if (token === null || sessionId === "") return;
    fetch(`/api/translation/config/${sessionId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        const orderedLanguages = orderLanguages(data.languages);
        setLanguages(orderedLanguages);
        setDocTypes(data.available_types);
        setMapOpt(data.map_options);
        setLoading(false);
      })
      .catch((error) => {
        console.error(
          "Erreur lors du chargement des configurations de traduction:",
          error
        );
        setError(error);
        setLoading(false);
      });
  }, [sessionId, token]);

  return { languages, docTypes, setDocTypes, mapOpt,setMapOpt, loading, error };
}

interface UseDocumentUploaderProps {
  file: File | null;
  method: string;
  sessionId: string
}

function useDocumentUploader({ file, method, sessionId }: UseDocumentUploaderProps) {
  const [usage, setUsage] = useState<any>(null);
  const [error, setError] = useState<Error | null>(null);
  const [errorCorupt, setErrorCorupt] = useState<string | null>(null)
  const [loading, setLoading] = useState(true);
  const [loaderEstimate, setLoaderEstimate] = useState(false)
  const { token } = useAuth()

  useEffect(() => {
    const postDocument = async () => {
      if (!file || !method || token === null || sessionId === "") return;
      setLoaderEstimate(true)

      const formData = new FormData();
      formData.append("app_session_id", sessionId);
      formData.append("method", method);
      formData.append("file", file);

      try {
        const response = await fetch(
          `/api/translation/document`,
          {
            method: "POST",
            headers: {
              'Authorization': `Bearer ${token}`
            },
            body: formData,
          }
        );
        if (!response.ok) {
          const errorData = await response.json();
          if (response.status === 400 && errorData.detail) {
            setErrorCorupt(errorData.detail); 
          } else {
            throw new Error(errorData.detail || "An unknown error occurred");
          }
        } else {
          const data = await response.json();
          console.log(data)
          if(errorCorupt) setErrorCorupt(null)
          setUsage(data);
        }
        setLoaderEstimate(false);
      } catch (error) {
        console.error("Erreur lors de l'envoi du document:", error);
        setError(
          error instanceof Error
            ? error
            : new Error("Une erreur inconnue est survenue")
        );
        setLoaderEstimate(false);
      }
    };

    postDocument();
  }, [file, method, sessionId, token]);

  return { usage, error, loading, errorCorupt ,loaderEstimate};
}
interface TranslateDocumentProps {
  file: File | null;
  sessionId: string;
  selectedLanguage: string;
  method: string;
  token: string | null;
  sourceLanguage: string;
}

const translateDocument = async ({
  sessionId,
  file,
  selectedLanguage,
  method,
  token,
  sourceLanguage
}: TranslateDocumentProps) => {
  if (!file || !token || sessionId === "" || !selectedLanguage || !method) {
    console.log("Tous les champs sont requis pour la traduction.");
    return;
  }

  try {
    const response = await fetch(`/api/translation`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        app_session_id: sessionId,
        method: method,
        source_language: sourceLanguage,
        target_language: selectedLanguage,
      }),
    });

    if (!response.ok) {
      throw new Error("Erreur de traduction.");
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `translated_${file.name}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);

    //onSuccess();
  } catch (error) {
    //onError(error);
    console.log(error);
  }
};

export { translateDocument, useDocumentUploader, useTranslationConfig }